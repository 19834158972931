import React from "react"
import PropTypes from "prop-types"

// Utilities
import startCase from "lodash/startCase"

// Components
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import PostTitle from "gatsby-theme-blog/src/components/post-title"
import PostFooter from "gatsby-theme-blog/src/components/post-footer"
import { Link } from "gatsby"
import PostList from "gatsby-theme-blog/src/components/post-list";

const Tags = ({ location, pageContext, data }) => {
  const { site, allMdx } = data
  const posts = data.allBlogPost.nodes
  const { tag } = pageContext
  const { totalCount } = allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  const title = `${startCase(tag)} Blog Posts`


  return (
    <Layout location={location} title={site.siteMetadata.title}>
        <SEO
            title={title}
        />
        <main>
          <article>
            <header>
              <PostTitle>{tagHeader}</PostTitle>
            </header>
            <section>
              <PostList posts={posts} />
            </section>
          </article>            
        </main>
        <Link to="/tags">All tags</Link>
        <PostFooter />
    </Layout>
  )
}

export default Tags

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            slug: PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}