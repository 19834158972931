import { graphql } from "gatsby"
import TagPage from "../gatsby-theme-blog/components/tag"

export default TagPage

export const tagQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allBlogPost(filter: {tags: {in: [$tag]}}) {
      nodes {
        body
        date
        title
        slug
        excerpt
        tags
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          slug
          frontmatter {
            title
          }
        }
      }
    }
  }
`