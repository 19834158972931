/** @jsx jsx */
import { Styled, jsx, css } from "theme-ui"
import { Link } from "gatsby"
import PostDate from "gatsby-theme-blog/src/components/post-date";
import React from "react";

const PostLink = ({ title, slug, date, excerpt }) => (
  <article>
    <header>
      <Styled.h2
        sx={{
          mb: 0,
          lineHeight: 1.5,
        }}
      >
        <Styled.a
          as={Link}
          sx={{
            textDecoration: `none`,
          }}
          to={slug}
        >
          {title || slug}
        </Styled.a>
      </Styled.h2>
      <small
        sx={{
          color: `tertiary`,
        }}
      >Last updated: {date}</small>
    </header>
    <section>
      <Styled.p
        sx={{
          mb: 60,
          mt: 10,
        }}
      >
        {excerpt}
      </Styled.p>
    </section>
  </article>
)

export default PostLink
